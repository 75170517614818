import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ENTRADAS_MERCANCIA
export default class InventarioService {
  get (id) {
    return http.get(`${baseUrl}/ver/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
