<template>
  <div class="card w-full">
    <div class="flex">
      <Button
        label="Atras"
        icon="pi pi-arrow-left"
        class="p-button-secondary p-mr-2 p-mb-2"
        @click="$router.go(-1)"
        size="small"
      />
    </div>
     <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
       <div>
         <strong class="mr-2">
           {{_invetario.Operacion}} #:
         </strong>
         <span>
            {{_invetario.Id}}
          </span>
       </div>
       <div>
          <strong class="mr-2">
            Fecha:
          </strong>
          <span>
            {{_invetario.DocDate}}
          </span>
       </div>
       <div>
          <strong class="mr-2">
            Bodega:
          </strong>
          <span>
            {{_invetario.WhsCode}} / {{_invetario.WhsName}}
          </span>
       </div>
     </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
      <div>
        <strong class="mr-2">
          Motivo:
        </strong>
        <span>
            {{_invetario.Motivo}}
          </span>
      </div>
      <div>
        <strong class="mr-2">
          Comentarios:
        </strong>
        <span>
            {{_invetario.Comments}}
          </span>
      </div>
    </div>
    <div class="flex w-full my-4">
      <DataTable
        :value="_invetario.Details"
        class="w-full"
        size="small"
        style="font-size: 0.75rem"
        stripedRows
        paginator :rows="10" :rowsPerPageOptions="[10,20,50,100,150,200,300,500,1000]"
        scrollable
        scrollHeight="400px"
      >
        <Column field="ItemCode" header="Cod. Articulo"/>
        <Column field="ItemName" header="Articulo"/>
        <Column field="Quantity" header="Cantidad"/>
        <Column field="BatchNumber" header="Lote"/>
        <Column field="FecVcto" header="Fec. Vecimiento"/>
      </DataTable>
    </div>
    <div class="flex w-full">
      <strong class="mr-2">Relizado por: </strong>
      <span>{{_invetario.FullName}}</span>
    </div>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import InventarioService from './services/inventario'
const _InventarioService = new InventarioService()
const route = useRoute()
const _invetario = ref({
  Id: null,
  WhsCode: null,
  WhsName: null,
  Motivo: null,
  Comments: null,
  FullName: null,
  DocDate: null,
  Operacion: null,
  Details: []
})
onMounted(() => {
  _InventarioService.get(route.params.Id).then(({ data }) => {
    _invetario.value = data
  })
})
</script>
<style scoped>

</style>
